@font-face {
    font-family: "Inter";
    src: url("assets//fonts/inter.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
}
body, textarea {
    font-family: "Inter", Sans-Serif !important;
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
}

/*Custom Scrollbar*/
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/*Remove unnecessary highlights from all types on inputs*/
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

/* To remove counter on input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}